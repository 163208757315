import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

import { getConfiguration } from 'utils/gdprConfiguration'
import { CmsProvider, FilterProvider, TagManagerProvider } from 'context'

import CareersPage from 'pages/static/about/Careers'

import Footer from 'components/layout/Footer'
import ScrollToTopButton from 'components/ScrollToTopButton'
import RouterScrollTop from 'components/layout/RouterScrollTop'
import ErrorBoundary from 'components/layout/ErrorBoundary'
import { JobsNavigation } from 'components/layout/JobsNavigation'
import { HeaderProvider } from 'context/HeaderContext'
import Metatags from 'components/Metatags'

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
  Sentry.init({ dsn: process.env.SENTRY_DSN })
}

export const JobsApp = () => {
  const { advertising } = getConfiguration()
  useEffect(() => {
    if (advertising) {
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
      ReactPixel.pageView()
    }
  }, [advertising])

  return (
    <Router>
      <Metatags isJobsPage />
      <RouterScrollTop>
        <CmsProvider>
          <ErrorBoundary>
            <FilterProvider>
              <HeaderProvider>
                <TagManagerProvider>
                  <header>
                    <JobsNavigation />
                  </header>

                  <div className="main-container jobs-main-container">
                    <CareersPage />
                  </div>

                  <Footer />
                  <ScrollToTopButton />
                </TagManagerProvider>
              </HeaderProvider>
            </FilterProvider>
          </ErrorBoundary>
        </CmsProvider>
      </RouterScrollTop>
    </Router>
  );
};
