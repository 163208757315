import React, { useContext, useEffect, useState } from 'react'
import classnames from 'classnames'

import { HeaderContext } from '../../context/HeaderContext'

export const JobsNavigation = () => {
  const { headerSlide, showHeader, hideHeader, animated } = useContext(HeaderContext)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > 115 && currentScrollY > lastScrollY) {
      hideHeader()
    } else {
      showHeader()
    }
    setLastScrollY(currentScrollY)
  }

  return (
    <header
      className={classnames('page-header', { '-animated': animated })}
      style={{
        transform: `translateY(${headerSlide})`,
      }}
    >
      <nav className="page-navigation">
        <div className="container -fluid">
          <div className="page-navigation__logo">
            <a href={process.env.WEBSHOP_URL} target="_top">
              <img alt="TILCRA" loading="lazy" src="/images/tilcra-logo-white.svg" />
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}
