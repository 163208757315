import React, { useEffect, useState, useContext, useCallback } from 'react'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import get from 'lodash/get'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'

import SearchInput from 'components/shared/forms/SearchInput'

const AllCategory = 'Alle'

const CMS_CONTENT_ID = '74Ei9uYv2xzsfV0qe0epDo'

const CareersPage = ({ setPaths }) => {
  const { getEntry } = useContext(CmsContext)

  const [categories, setCategories] = useState([AllCategory])
  const [activeCategory, setActiveCategory] = useState(AllCategory)
  const [jobs, setJobs] = useState([])
  const [headerImage, setHeaderImage] = useState()
  const [filteredJobs, setFilteredJobs] = useState([])

  useEffect(() => {
    getJobPage()
  }, [])

  useEffect(() => {
    filterJobs()
  }, [activeCategory])

  const filterJobs = query => {
    if (query) {
      const searchQuery = query.toLowerCase()
      setFilteredJobs(
        jobs.filter(
          ({ title, category, time, type }) =>
            includes(title.toLowerCase(), searchQuery) ||
            includes(category.toLowerCase(), searchQuery) ||
            includes(time.toLowerCase(), searchQuery) ||
            includes(type.toLowerCase(), searchQuery),
        ),
      )
    } else {
      setFilteredJobs(
        jobs.filter(
          ({ category }) => activeCategory === AllCategory || category === activeCategory,
        ),
      )
    }
  }

  const onSearch = searchQuery => {
    setActiveCategory(AllCategory)
    filterJobs(searchQuery)
  }

  const getJobPage = useCallback(() => {
    getEntry(CMS_CONTENT_ID)
      .then(
        ({
          fields: {
            jobs,
            headerImage: {
              fields: {
                file: { url },
              },
            },
            jobsCategories,
          },
        }) => {
          setHeaderImage(`${url}?fm=jpg&fl=progressive&q=80`)
          setCategories([AllCategory, ...jobsCategories])
          const formattedItems = jobs.map(({ fields, sys: { id } }) => ({
            ...fields,
            pdfFile: get(fields, 'pdfFile.fields.file.url'),
            id,
          }))
          setFilteredJobs(formattedItems)
          setJobs(formattedItems)
        },
      )
      .catch(err => console.error(err))
  }, [activeCategory])

  return (
    <div className="m-jobs-page">
      {headerImage && (
        <div className="m-jobs-page__image mb-30">
          <img className="img-responsive" loading="lazy" src={headerImage} />
        </div>
      )}

      <div className="m-jobs-page__search mb-30 mb-md-50">
        <SearchInput
          onChange={value => onSearch(value)}
          placeholder={getTranslation('staticPages.about.careers.searchPlaceholder')}
        />
      </div>

      <h2 className="page-title">{getTranslation('staticPages.about.careers.recentJobs')}</h2>

      <div className="m-jobs-page__categories mb-30">
        {categories.map(category => (
          <div
            className={classnames('m-jobs-page__category-item', {
              '-active': category === activeCategory,
            })}
            key={`category_${category}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </div>
        ))}
      </div>

      <div className="m-jobs-page__jobs-container">
        {isEmpty(filteredJobs) ? (
          <h1>No results</h1>
        ) : (
          filteredJobs.map(({ id, title, type, time, city, category, pdfFile }) => (
            <div className="m-jobs-page__job-item" key={`job_${id}`}>
              <div className="m-jobs-page__job-item-details">
                <span className="m-jobs-page__job-item-position">
                  {[city, type, time, category].filter(item => !!item).join(' | ')}
                </span>
                <span className="font-18 text-medium">{title}</span>
              </div>
              <div className="m-jobs-page__show-offer">
                {pdfFile ? (
                  <a
                    className="btn -blue -outline pointer"
                    href={pdfFile}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {getTranslation('staticPages.about.careers.showOffer')}
                  </a>
                ) : (
                  <p>{getTranslation('staticPages.about.careers.comingSoon')}</p>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}
export default withBreadcrumbs(CareersPage)
